// AlbumCover.js
import React, { useRef } from "react";
import { Box, Typography } from "@mui/material";
import { colors } from "../lib/theme";
import AlbumArt from "../../shared/AlbumArt";

const AlbumCover = ({
  imageUrl,
  handleImageClick,
  handleImageUpload,
  user,
  userId,
  sharedWith,
}) => {
  const fileInputRef = useRef(null);

  const canEdit = user && (user.uid === userId || sharedWith.includes(user.uid));

  return (
    <Box
      onClick={() => canEdit && handleImageClick(fileInputRef)}
      sx={{
        bgcolor: colors.darkNavy,
        p: 4,
        color: colors.navy,
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: canEdit ? "pointer" : "default",
      }}
    >
      <AlbumArt canEdit={canEdit} imageUrl={imageUrl} size="clamp(200px, 40vw, 300px)" />
      {canEdit && !imageUrl && (
        <Typography
          sx={{
            position: "absolute",
            color: "white",
            textAlign: "center",
            fontSize: "1rem",
            fontWeight: "bold",
            transform: "rotate(-5deg)",
          }}
        >
          Click to upload<br/>album cover
        </Typography>
      )}
      {canEdit && (
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleImageUpload}
          accept="image/*"
          style={{ display: "none" }}
        />
      )}
    </Box>
  );
};

export default AlbumCover;
